@import '../../styles/variables.scss';

.TresorTrack {
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}

.TresorTrack--nav-showing {
  @media screen and (max-width: $mobile) {
    //  overflow: hidden;

    .nav {
      opacity: 1;
      transition: transform 0.125s ease-out, opacity 0s linear;
      display: unset;
    }

    .page {
      transform: translateY(140px);
      // margin-top:0;
    }
  }
}

.nav {
  position: absolute;
  height: 60px;
  width: 100%;
  z-index: 10;
  left: 0;
  //position: fixed;
  top: 0;
  transition: transform 0.125s ease-out, opacity 0s linear 0.125s;

  @media screen and (max-width: $mobile) {
    opacity: 0;
    display: none;
    height: 140px;
  }
}

.page {
  background-color: $gray-100;
  flex: 1;
  height: 100%;
  //margin-left: 80px;
  margin-top: 60px;
  position: relative;
  transition: transform 0.125s ease-out;
  z-index: 2;

  @media screen and (max-width: $mobile) {
    margin-left: 0;
    margin-top: 0;
  }
}