@import '../../styles/variables.scss';

.wrap {
  padding: $space-s;
  border: 1px solid $gray-300;
  border-radius: $roundness-m;
}

.name {
  margin-bottom: $space-xs;
}

.meta {
  display: flex;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.metaItem {
  flex: 1;

  @media screen and (max-width: $mobile) {
    &:first-child {
      margin-bottom: $space-xxxs;
    }
  }
}

.metaItem label div
{
  display: flex;
}