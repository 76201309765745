@import '../../styles/variables.scss';

.badge {
  border-radius: 12px;
  border: 1px solid $gray-500;
  color: $gray-700;
  font-size: 0.857142857rem;
  font-weight: 500;
  line-height: 25px;
  padding: ($space-xxs / 2) $space-s;
  text-align: center;
  margin-right: $space-xxs;
}
.badgeMargin {
  border-radius: 12px;
  border: 1px solid $gray-500;
  color: $gray-700;
  font-size: 0.857142857rem;
  font-weight: 500;
  line-height: 25px;
  padding: ($space-xxs / 2) $space-s;
  text-align: center;
  margin-left:50%;
}
