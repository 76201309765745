@import '../../styles/variables.scss';

.wrap {
  margin-bottom: $space-xl;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media screen and (max-width: $desktop-s) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
