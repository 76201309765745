@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.OK,
.INACTIVE {
  border-radius: 5px;
  height: 10px;
  width: 10px;
}

.OK {
  background-color: $green-600;
}

.INACTIVE {
  background-color: $gray-500;
  display:flex;
}

// Critical errors

.COMMUNICATION_ERROR,
.SIGNALSTRENGTH_CRITICAL,
.BATTERY_CRITICAL,
.TEMPERATURE_HIGH {
  color: $orange-600;
  display:flex;
}

.BATTERY_LOW {
  color: $orange-300;
  display:flex;
}
.DISABLED {
  color: $red-600;
  display:flex;
}
.MOVEMENT {
  color: $red-600;
  display:flex;
}
.FALSE_ALERT
 {
  color: $red-600;
  display:flex;
}
.DEVICE_REPAIRED
 {
  color: $orange-300;
  display:flex;
}
.WORK_IN_PROGRESS
 {
  color: $gray-700;
  display:flex;
}
.WORK_COMPLETE
 {
  color: $green-600;
  display:flex;
}
.READY
 {
  color: $blue-300;
  display:flex;
}
.status {
  background-color: $red-600;
  border-radius: 5px;
  height: 10px;
  width: 10px;
  display:flex;
}
