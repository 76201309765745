@import '../../styles/variables.scss';

.mobileView
{
  flex: unset!important;
  width: 140px;
  margin-right: 0!important;
}

.mobileView:nth-child(odd)
{
  @media only screen and (min-width: 355px) and (max-width: 415px)
  {
    margin-right: 7px!important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .mobileView
  {
    width: 195px;
  }
}


.label {
  color: $gray-700;
  display: block;
  font-size: 1rem;
  line-height: 1.4;
  padding-bottom: $space-xxs;
}

.value {
  color: $gray-900;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;

  & > * {
    margin-right: $space-xs;
   
  }
}

.tooltip
{
  font-size: 1rem!important;
  padding: 8px!important;
  line-height: 1.4;
  max-width: 300px;

  @media screen and (max-width: $mobile)
  {
    max-width: 70vw;
  }
}

.tooltipItemWrapper > i
{
  margin-left: 3px;
}

.tooltipItemWrapper
{
  display: inline-flex!important;
  align-items: center;
}
