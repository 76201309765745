@import '../../styles/variables.scss';

.tabs {
  display: flex;
  border-bottom: 2px solid $gray-300;
  justify-content: flex-start;
  user-select: none;
  align-items: center;
}

.tabs > a {
  color: $gray-700;
  display: block;
  font-size: 1.071428571rem;
  font-weight: 500;
  padding: $space-xs $space-m;
  margin-right: $space-xs;
  position: relative;

  &:focus {
    box-shadow: none;
  }

  &::after {
    background-color: $gray-900;
    bottom: -2px;
    content: '';
    height: 2px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.225s ease-out;
    width: 100%;
  }
}

.tabs > :global(a.active) {
  color: $gray-900;

  &::after {
    opacity: 1;
  }
}
