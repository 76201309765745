$space-xxxxs: 2px;
$space-xxxs: 5px;
$space-xxs: 8px;
$space-xs: 14px;
$space-s: 18px;
$space-m: 24px;
$space-l: 36px;
$space-xl: 48px;
$space-xxl: 56px;
$space-xxxl: 64px;
