@import '../../styles/variables.scss';

.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 100%;
    max-height: 200px !important;
  }

}

.mainInfo {
  display: flex;
  padding: $space-m $space-m 0 $space-m;

  & > * {
    flex: 1;

    @media screen and (max-width: $mobile) {
      flex: 0;

      &:first-child {
        margin-bottom: $space-s;
      }
    }
  }

  @media screen and (max-width: $mobile) {
  }
  @media screen and (max-width: $mobile) {
    flex-direction: column;
    justify-content: space-between;
    min-height: 110px;
  }
}

.deviceStatus {
  margin-top: $space-l;
  padding: 0 $space-m $space-m $space-m;
}
