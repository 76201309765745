@import '../../styles/variables.scss';

.wrap {
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  padding: $space-m;
}

.row {
  display: flex;
  justify-content: flex-start;
  //padding-bottom: $space-m;
  flex-wrap: wrap; //

  & > label:nth-child(4)
  {
   // display: none;
  }

  & > * {
    display: block;
    flex: 1;
    line-height: 1;
    margin-bottom: 36px;
    flex-basis: 45%;

    

    @media screen and (max-width: 550px) {
      //flex: unset;
      margin-bottom: $space-m;
    }
  }

  & > label:last-child
  {
      display: none;
  }

  & > label:nth-child(odd) 
  {
  padding-right: 5px;
  flex-basis: 55%;
  }

  & > * > span {
    @media screen and (max-width: 550px) {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 550px) {
    //display: block;
    //justify-content: unset;
    padding-bottom:0;
  }
}

.inspectionNotes i 
{
  font-style: italic;
}

.inspectionNotes
{
  word-break: break-word;
  line-height: 1.4!important;
}


@media screen and (min-width:$desktop-l), screen and (min-width: 730px) and (max-width: $desktop-s)
{
  .row > * 
  {
    flex-basis: 33.3%!important;
    padding-right: 5px;
  }

  .row > label:last-child
  {
    display: block;
  }

  .row > label:nth-child(4):last-child
  {
    display: none;
  }
}


@media screen and (max-width: 550px)
{
  .row > * 
  {
    flex-basis: 100%;
}
}