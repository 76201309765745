@import '../../styles/variables.scss';

.deviceWrap {
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  padding-top: $space-s;
  padding-left: $space-s;
  padding-right:$space-s;
}
// Styles go here
.holder{

   
  background-color: $gray-300;
  margin:$space-s;
}
.lineHeight{
  line-height: 1.571428571;
}

.inputWrap{
align-items: center;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
//padding: $space-s 0 $space-m;

}
.wrap {
align-items: center;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
//padding: $space-s 0 $space-m;
}

.icon {
color: $green-600;
margin-bottom: $space-xs;
margin-right: $space-xs;
}
.error {
color: $red-600;
margin-bottom: $space-xs;
margin-right: $space-xs;
@media screen and (max-width: $mobile) {

  margin-bottom: 0;
  
}
}
.body {
max-width: 380px;
padding-top: $space-xs;
text-align: center;
}
.bodyLeft {
  max-width: 380px;
  padding-top: $space-xs;
  
  }
  .bannerImage {
    background-color: $gray-900;
    border-radius: $roundness-m $roundness-m 0 0;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile) {
      height: 120px;
    }
    & img {
      max-height: 50%;
     max-width: 50%;
    }
  
  }

.wrapvert{
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;

}