@import '../../styles/variables.scss';

.xxs {
  margin-bottom: $space-xxs;
}

.xs {
  margin-bottom: $space-xs;
}

.s {
  margin-bottom: $space-s;
}
.sliderPadding{
  padding:$space-xs;
}
.m {
  margin-bottom: $space-m;
}

.l {
  margin-bottom: $space-l;
}

.xl {
  margin-bottom: $space-xl;
}

.lineHeight{
  line-height: 1.571428571;
}