@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  height: 69px;
  justify-content: space-between;
  padding: 0 $space-m;

  /** Search input */
  & > *:first-child {
    flex: 1;
  }
}

  /** Filter */
.button 
{
  & > *:last-child {
    flex: 0.45;
    margin-left: $space-m;
  }
}
