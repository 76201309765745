@import '../../styles/variables.scss';



.mobileView
{
  min-height: 100px;
}
.wrap{
  word-break: break-word;
}
.row {
  
  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}

.indicator{
  display:inline-flex;
}
a.row {
 

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}
.row {
 // margin-top: $space-s;
  //align-items: center;
  border-bottom: 1px solid $gray-300;
  //display: flex;
  min-height: 74px;
  height: 100%;
  padding: $space-s;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: $space-xxs $space-xs;
    min-height: 85px;
    height: 100%;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    height: auto;
    padding: $space-xxs;
  
  }
}

.filter {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
  .spacer{
    margin-bottom:$space-xs;
  }
  .status {
    background-color: $gray-300;
    border-radius: 2px;
    height: 12px;
    //margin-top: 6px;
    margin-right: $space-xxs;
    width: 12px;
  
    &.ok {
      background-color: $green-600;
    }
  
    &.warning {
      background-color: $orange-300;
    }
  
    &.critical {
      background-color: $orange-600;
    }
  
    &.error {
      background-color: $red-600;
    }

    &.information {
      background-color: $blue-300;
    }
    &.telemetry {
      background-color: $gray-700;
    }
  }
  