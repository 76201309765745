@import '../../styles/variables.scss';

.controls {
  align-items: center;
  align-self: center;
  color: $gray-700;
  display: flex;
  margin-right: auto;

  & > * {
    margin-right: $space-xs;
  }
}
