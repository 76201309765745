@import '../../styles/variables.scss';

.label {
  display: block;
  padding-bottom: $space-xs;
}

.icon {
  color: $gray-700;
  float: left;
  margin-right: $space-xxs;
  position: relative;
  top: -4px;
}

.light {
  color: $gray-700;
}
.right {
  color: $gray-700;
  margin-left: auto;
  padding-left: $space-s;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.left {
  padding-right: $space-s;
}