@import '../../styles/variables.scss';

.text {
  max-width: 600px;
  padding: 0 $space-m;
}

.icon {
  color: $red-800;
}

.response {
  background-color: $gray-200;
  border: 1px solid $gray-300;
  font-family: monospace;
  line-height: 1.4;
  margin-top: $space-xxs;
  padding: $space-s;
}

.buttons > * {
  margin-right: $space-s;
  margin-bottom: $space-s;
}
