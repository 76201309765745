@import '../../styles/variables.scss';

.textarea {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  min-height: 144px;
  padding: $space-xs;
  position: relative;
  resize: vertical;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled] {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}
