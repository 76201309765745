@import '../../styles/variables.scss';

.autocomplete {
    &:focus {
        border: 1px solid $gray-500;
      }

      outline: none;
    text-align: left;
    width: 100%;
    align-items: flex-start;
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      border: 1px solid #d9d9d9;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 1rem;
      height: 36px;
      padding: 0 14px;
      position: relative;
      transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
      width: 100%;
      &:focus {
        border: 1px solid $gray-500;
        box-shadow: 0 0 0 3px #d9d9d9;
        border-radius: 3px;
        outline: none;
      }
  }
  .autocompleteDiv{
    position: absolute;
    z-index: 99999;
    text-align: left;
   padding-right: $space-xl;
    width: 100%;
   
    &:focus {
      border: 1px solid $gray-500;
    }
  }
  .autocompleteSuggestion{
    text-align: left;
    width: 100%;
    align-items: flex-start;
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      //border-radius: 3px;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 1rem;
      min-height: 24px;
      padding: $space-xs;
      position: relative;
      transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
      width: 100%;
  }
  .autocompleteSuggestionActive{
   
    text-align: left;
    width: 100%;
    align-items: flex-start;
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      //border-radius: 3px;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 1rem;
      min-height: 24px;
      padding: $space-xs;
      position: relative;
      transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
      width: 100%;
  
      background-color: '#ffffff';
       cursor: 'pointer';
  }