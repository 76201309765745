@import '../../styles/variables.scss';

.wrap {
  display: flex;
  padding: $space-m;
  border: 1px solid $gray-300;
  border-radius: $roundness-m;
  width:100%;
  @media screen and (max-width: $mobile) {
    word-break: break-word;
    padding: 10px;
    
  }

  & > * {
    flex: 1;
    padding: 5px;
  }
}
.font{
  @media screen and (max-width: $mobile) {
    font-size:0.9rem !important;
  }
}
.wrapSmall{
  display: flex;
  align-items: center;
}
.type {
  line-height: 1;
  @media screen and (max-width: $mobile) {
    font-size:0.9rem;
  }
}
.typeStatus {
  line-height: 1;
  margin-left: $space-xxs;
  @media screen and (max-width: $mobile) {
    font-size:0.9rem;
  }
}
.icon {
  color: $red-600;
}
.iconOk {
  color: $green-600;
}