@import '../../styles/variables.scss';

input[class*='PhoneInputInput']
{
    appearance: none;
    border-radius: $roundness-m;
    border: 1px solid $gray-300;
    font-family: $font-stack;
    font-size: 1rem;
    height: 36px;
    padding: 0 14px;
    position: relative;
    transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
    width: 100%;
    padding-left: 50px;
    background: transparent;
}

div[class='PhoneInput'] > div:last-child, div[class='PhoneInput PhoneInput--focus'] > div:last-child
{
    width: 100%;
}

div[class*='PhoneInput--focus'] input
{
    border: 1px solid $gray-500;
    outline: none!important;
    box-shadow: 0 0 0 3px #d9d9d9;
    border-radius: 3px;
    outline: none;
}

div[class='PhoneInputCountry']
{
    position: absolute;
    z-index: 1;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 11px;
}