@import '../../styles/variables.scss';
@import '../../styles/shapes.scss';

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

img[src="http://maps.gstatic.com/mapfiles/api-3/images/mapcnt3.png"] {
  display: none;
}

.gm-style-iw>button {
  display: none !important;
}

::ng-deep .gm-style .gm-style-iw-c button {
  display: none !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw+div {
  display: none;
}

.wrap {
  position: relative;
  height: 100%;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.overMapErrorContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0
}

.overMapErrorInner {

  background-color: $white;
  margin: $space-m $space-m 0 $space-m;
  z-index: 1;
  text-align: center;

  border-radius: $roundness-xl;
  -webkit-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  -moz-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  padding: $space-xs;
}

.overMapBottomContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  height: auto;
  width: auto;
  margin: 0 $space-m $space-m $space-m;
  z-index: 1;
  border-radius: $roundness-xl;
  -webkit-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  -moz-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  padding: $space-m $space-s;
}

.avatar {
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 666px;
  margin-right: $space-xs;
}

.indicatorContainer {
  display: flex;
  flex-direction: row;
  font-size: 85%;
}



.indicatorLabel {
  color: grey
}

.indicatorContainer img {
  margin-right: 5px;
  object-fit: cover;

}

.indicatorText {}

.indicatorTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overMapTopInner {
  align-items: center;
  flex-direction: row;
  display: flex;
}

.overMapTopContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  height: auto;
  width: auto;
  margin: $space-m $space-m 0 $space-m;
  z-index: 1;

  border-radius: $roundness-xl;
  -webkit-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  -moz-box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  box-shadow: 0px 10px 18px 0px rgba(169, 169, 169, 1);
  padding: $space-xxs;
}

.map {
  float: top;
  height: 50%;
  width: 100%;

}

.mapFull {
  float: top;
  height: 100%;
  width: 100%;

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    /* your css rules for ipad portrait */
  }

  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    /* your css rules for ipad landscape */
  }

  @media screen and (max-width: $mobile) {
    // margin-top: $space-xxxl;
    // height: calc(100% - 64px);
  }
}

#map,
#pano {
  float: left;
  height: 100%;
  width: 100%;
}

div[id*="BottomPin"] div:nth-child(2) img {
  border-radius: 4px;
}