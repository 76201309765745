@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $space-m 0 $space-xl;
}

.icon {
  color: $green-600;
  margin-bottom: $space-m;
}

.body {
  max-width: 380px;
  padding: $space-m 0 $space-l;
  text-align: center;
}
