@import '../../styles/variables.scss';

.navItem {
  align-items: center;
  color: $gray-800;
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 66px;
  position: relative;
  justify-content: center;

  &::before {
    background-color: $brand;
    bottom: 0;
    content: '';
    opacity: 0;
    position: absolute;
    height: 3px;
    width: 40px;

    @include fade;
  }
}

:global(.active) .navItem::before {
  opacity: 1;
}

.icon {
  flex-direction: column;
  font-size: 1.8rem;
  height: 2rem;
  margin-bottom: 3px;
}

.notification {
  position: absolute;
  top: 12px;
  right: 17px;
  height: 6px;
  box-shadow: $depth-300;
  width: 6px;
  border-radius: 3px;
  background-color: $red-600;
}
