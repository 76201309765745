@import "../../styles/variables.scss";

.wrap {
  display: flex;
}

.label {
  align-items: center;
  background-color: $gray-100;
  border: 2px solid $gray-200;
  border-radius: $roundness-m;
  cursor: pointer;
  display: flex;
  margin-right: $space-s;
  flex-direction: column;
  padding: $space-xs 0;
  justify-content: center;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }
}

.labelActive {
  background-color: $white;
  border-color: $gray-900;
}

.img {
  margin-bottom: $space-xs;
  user-select: none;
}

.img img {
  width: 58px;
}

.text {
  font-size: 0.857142857rem;
  user-select: none;
  text-align: center;
}

.label input {
  height: 1px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}
