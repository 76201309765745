@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $space-m;
}

.page {
  margin-bottom: $space-xs;
  margin-right: $space-xxs;

  &:last-child {
    margin-right: 0;
  }

  & > *[disabled] {
    cursor: default !important;
  }
}
