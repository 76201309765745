@import '../../styles/variables.scss';

.filterBar {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  justify-content: space-between;
  padding: $space-xs $space-m;
}

.status {
  align-items: center;
  display: flex;
}

.totalText {
  color: $gray-700;
}

.loading {
  margin-right: $space-xs;
}

.events {
  padding: $space-s;
}
