@import '../../styles/variables.scss';

.file {
  align-items: center;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  list-style-type: none;
  padding: $space-xs $space-m;
  flex-wrap: wrap;
  word-break: break-word;
}

.img {
  margin-bottom: $space-xs;
  user-select: none;
  width:100%;
}
.smallPreview{
  user-select: none;
  width:10%;
}

.img img {
  width: 58px;
}
