@import '../../styles/variables.scss';

.wrap {
  position: relative;
}

.label {
  display: block;
  padding-bottom: $space-xs;
}

.serialWidth{
  height: 48px !important;
  //font-size: 1.6rem !important;
}
.input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}

.withIcon {
  padding-left: 40px;
}

.withLoading {
  padding-right: 40px;
}

.icon {
  color: $gray-700;
  left: $space-xs;
  position: absolute;
  top: $space-xxs;
  z-index: 2;
}

.loading {
  position: absolute;
  right: $space-xs;
  top: $space-xxs;
  z-index: 2;
}

.error {
  border-color: $gray-700;
}

.errorMessage {
  background-color: $gray-700;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
  color: $white;
  padding: $space-xs;
  position: absolute;
  top: 38px;
  z-index: 9;
}

input.spinHide::-webkit-outer-spin-button,
input.spinHide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.spinHide[type=number] {
  -moz-appearance: textfield;
}

.assetDashboard
{
  @media only screen and (min-device-width: $mobile) and (max-device-width: 1025px) and (orientation:landscape) {
    margin-top:10px;

    }
}