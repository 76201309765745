@import '../../styles/variables.scss';

.header {
  align-items: center;
  display: flex;
  line-height: 38px;
  height: 36px;
  justify-content: space-between;
  margin-bottom: $space-xxs;
  width:100%;
}

.title {
  color: $gray-700;
  font-size: 1rem;
}
.alarms 
{
  height: 100%;
  min-height: 36px;
}

.alarms p
{
  text-align:right;
  margin-left:5px;
}

.alarms *
{
  line-height: 1.4;
}