@import '../../styles/variables.scss';

.inputWrap {
  text-align: left;
}


@media print {
span{
  margin:40px;
}
  .main h3 {
    page-break-after: always;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

}

.row {
  
  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}


a.row {
 

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}
.row {
 // margin-top: $space-s;
  //align-items: center;
  border-bottom: 1px solid $gray-300;
  //display: flex;
  min-height: 74px;
  height: 100%;
  padding: $space-s;
  display: flex;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: $space-xxs $space-xs;
    min-height: 85px;
    height: 100%;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    height: auto;
    padding: $space-xxs;
  
  }
}