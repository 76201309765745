@import '../../styles/variables.scss';

.session {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:last-child {
    flex-shrink: 0;
    margin-left: $space-m;
  }

  @media screen and (max-width: $mobile) {
    display: block;

    & > *:last-child {
      margin: $space-s 0 0 0;
    }
  }
}
