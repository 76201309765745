@import '../../styles/variables.scss';


.mobileView
{
  min-height: 100px;
}

a.row {
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}

a.row.bbMode  {
  cursor: pointer;
  margin-bottom: 20px;
  /*background-color: white;
  border: 1px solid #d7500d;
  color: #ffffff;
  border-radius: 3px!important;*/
  border: 0;
  padding: 0;
  background-color: white;

  &:hover {
    background-color: white;
  }

  &:active {
    background-color: white;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.bbMode .title
{
font-size:125%;
color: black;
}

.bbMode .subtitle
{
font-size:115%;
color: rgb(10, 10, 10)
}

.bbMode.row
{
  padding: 0 8px 0 0;
}

.bbMode .middle
{
  //background-color: #f26925;
  //border: 1px solid #d7500d;
  //color: #ffffff;
  //border-radius: 3px 2px 2px 3px !important;
  width: 100%;
  min-height: 85px;
  padding: 10px;// 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &:hover {
 //   background-color: #f27739;
  }

  &:active {
   // background-color: #f26118;
  }
}

.bbMode i
{
  font-size: 32px;
}

.bbMode div[class*='OK'], .bbMode div[class*='INACTIVE'], .bbMode div[class*='status']
{
  width: 22px;
  height: 22px;
  border-radius: 10px;
}

.bbMode .left
{
  padding: 0 0 0 12px;
}

a.row.bbMode
{
  border: 1px solid #d9d9d9;//1px solid #d7500d;
  color: #ffffff;
  border-radius: 3px !important;
}

a.row2 {
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-200;
  }
}
.row {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  min-height: 74px;
  height: 100%;
  padding: 5px $space-m;

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: $space-xxs $space-xs;
    min-height: 85px;
    height: 100%;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    height: auto;
    padding: $space-xxs;
  
  }
}

.row2 {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  height: 74px;
  padding: 0 $space-m;
  width: 100%;
  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: 0 $space-xs;
    height: 85px;
  }
}
.multiwrap{
 
  display: flex;
  margin: 0;
  
}
.size--l {
  height: 90px;

  .subtitle {
    margin-top: $space-xxs;
  }

  @media screen and (max-width: $mobile) {
    height: 124px;
    padding: 0 $space-m;

    .rightMobile {
      margin-top: $space-xs;
    }
  }
}

.title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: unset;
  line-height: 1.2;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    font-size: 1.1rem;
    line-height: 1.4;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
      font-size: 1.1rem;
    line-height: 1.4;
     }
}



.subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: unset;
  line-height: 1.2;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    font-size: 0.9rem;
    line-height: 1.4;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
      font-size: 0.9rem;
    line-height: 1.4;
     }
}

.subtitle {
  color: $gray-700;
  margin-top: $space-xxxs;
}

.left {
  padding-right: $space-s;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    padding-right: $space-xxs;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
      padding-right: $space-xxs;
     }
}

.right {
  color: $gray-700;
  margin-left: auto;
  padding-left: $space-s;
  text-align: right;

  @media screen and (max-width: $mobile) {
    display: none;
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    font-size: 0.9rem;
    line-height: 1.2;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
      font-size: 0.9rem;
    line-height: 1.2;
     }
}
.rightnopad {
  color: $gray-700;
  
  margin-top: $space-s;
  padding-right: $space-m;
  border-bottom: 1px solid $gray-300;
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.rightMobile {
  color: $gray-700;
  display: none;
  margin-top: $space-xxxs;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}
