@import '../../styles/variables.scss';
@import '../../styles/shapes.scss';

.wrap {
  position: relative;
}

.select {
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  box-shadow: $depth-200;
  color: $gray-900;
  cursor: pointer;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 400;
  height: 36px;
  line-height: 34px;
  padding: 0 $space-s;
  position: relative;
  user-select: none;
  width: 100%;

  @include focus;

  &:focus {
    border: 1px solid $gray-500;
  }

  &:active {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    box-shadow: none;
    color: $gray-700;
    cursor: not-allowed;
  }
}

.carret {
  margin-left: auto;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 12px;

  @include carret;
}
