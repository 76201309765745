@import '../../styles/variables.scss';

.wrap {
  display: flex;
  margin-top: $space-s;
  //padding-bottom: $space-m;
  padding-right: $space-s;
  position: relative;

  &:first-child {
    margin-top: $space-xxxs;
  }

  &:first-child:last-child {
    padding-bottom: $space-s;
  }

  &:last-child .timeline {
    display: none;
  }
}

.timeline {
  position: absolute;
  left: 16px;
  top: 24px;
  bottom: -40px;
  width: 3px;
  background-color: $gray-300;
  z-index: 1;
}

.wrap:last-child .timeline {
  display: none;
}

.avatar {
  flex: 0 0 auto;
  margin-right: $space-s;
  position: relative;
  z-index: 2;
  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) 
  {
  margin-right: 2px; 
  }
}

.bodyWrap {
  flex: 1;
}

.title {
  margin: 10px 0 $space-m 0;
  line-height: 1.8;
  width: 100%;
  display: inline-block;
}

.body {
  margin-bottom: $space-m;
}

.datetime {
  color: $gray-700;
  margin-bottom: $space-xxs;
  line-height: 1.2rem;
}

.action
{
    //width: 20%;
   // display: inline-block;
   display: flex;
   margin: 10px 0 $space-m $space-xs;
    //float: right;
    //text-align: right;

    @media only screen and (max-device-width: $mobile)
    {
      //margin: 0 0 10px 0;
      //float:unset;
      //display: block;
     // width: unset;
    }
  
}
.action span:not(:first-child)
{
 // margin-left: 5px;
}

.titleActionWrapper
{
  display: flex;
  margin-left: 5px;
}

@media screen and (max-width: 670px), screen and (min-width: ($desktop-s)+1) and (max-width: $desktop-l)
{
  .action
  {
    flex-wrap: wrap;
    flex-direction: column;

   & span:not(:first-child)
   {
     margin-left: 0;
   }

  }

  
}

