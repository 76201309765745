@import '../../styles/variables.scss';

.clearAlarm {
  //max-width: 475px;
  padding-bottom: $space-xl;
}
.wrap {
  display: flex;
  
  align-items: center;
  & > * {
    flex: 1;
  }
  max-width: 270px;
}
.text{
  font-size: 1.21rem;
  
}
.spacer {
  padding-left:$space-xxxs;
  max-width: 25px
}