@import '../../styles/variables.scss';

// Styles go here
.holder{

   
    background-color: $gray-300;
    margin:$space-m;
}

[class*="bc"],
{
  color: black!important;
  -webkit-text-fill-color: black!important;
}
 
.inputWrap{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;

}
.wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  //padding: $space-s 0 $space-m;
}
.wrapvert{
  color: #242424;
  display: flex;
  font-size: 1.571428571rem;
  font-weight: 500;
  height: 24px;
  line-height: 1.67rem;
}
.margin{
  margin-left: $space-xs;
}
.icon {
  color: $green-600;
  margin-bottom: $space-xs;
}
.error {
  color: $red-600;
  margin-bottom: $space-xs;
  @media screen and (max-width: $mobile) {
  
    margin-bottom: 0;
    
  }
}
.body {
  max-width: 380px;
  padding-top: $space-xs;
  text-align: center;
}
.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile) {
    height: 120px;
    
    & img, & div {
      height: 100%!important;
      // max-width: 100%;
     }
  }
}