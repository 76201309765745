@import '../../styles/variables.scss';

.inputRow {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.inputRow > * {
  flex: 1;
  margin: 0 $space-m $space-m 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}

.inputRowNoPadding {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.inputRowNoPadding > * {
  flex: 1;
 

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}
