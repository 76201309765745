@import '../../styles/variables.scss';

.banner {
  border-radius: $roundness-m;
  border-style: solid;
  border: 1px solid $gray-900;
  color: $gray-900;
  padding: $space-s;
  line-height: 1.4;
  text-align: center;
}

.danger {
  border-color: $red-800;
  color: $red-800;
}
