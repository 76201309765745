@import '../../styles/variables.scss';

.clearAlarm {
  //max-width: 475px;
  padding-bottom: $space-xl;
}
.wrap {
  display: flex;
  
  align-items: center;
  & > * {
    flex: 1;
  }
  max-width: 170px;
}
.spacer {
  padding-left:$space-xxxs;
  max-width: 25px
}
.wyswigWrapper
{
  overflow: auto;
  height: 13vh;
  line-height: 1.4;
  @media screen and (max-width: $mobile) {
    height: 8vh;
  }
}

.wyswigWrapper i, .border i
{
  font-style: italic!important;
}

.wyswigOuterWrapper [title="Styles"], .wyswigOuterWrapper [title="Link"], .wyswigOuterWrapper [title="Clear formatting"]  {
  display: none;
}

.wizzardPickerWrapper > div:first-child
{
  display: block;
}

.wizzardPickerWrapper input {
  appearance: none;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  font-family: $font-stack;
  font-size: 1rem;
  height: 36px;
  padding: 0 14px;
  position: relative;
  transition: box-shadow 0.1s ease-in, border-color 0.1s ease-in;
  width: 100%;

  &:focus {
    border: 1px solid $gray-500;
  }

  @include focus;

  &::placeholder {
    color: $gray-700;
  }

  &[disabled],
  [disabled] & {
    background-color: $gray-100;
    color: $gray-700;
    cursor: not-allowed;
  }
}