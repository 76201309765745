@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  background-color: $gray-900;
  border-radius: 17px;
  display: flex;
  height: 34px;
  justify-content: center;
  width: 34px;
}
