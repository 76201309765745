@import '../../styles/variables.scss';

.empty {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
  padding: $space-xl;
}
.wrap {
  width:100%;
  display: flex;
  padding: $space-xxs;
  
  & > * {
    flex: 1;
  }
}
.wrapper {
  display: flex;
  padding: 5px;
  min-width: $space-l;
  
  & > * {
    flex: 1;
  }
}
.maintenance{
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
  padding: $space-xl;

}
.right{
  color: $orange-600;
  font-size: 1rem;
  font-weight: 500;
}
.text{
  display: flex;
  padding-left:$space-xxs;
}