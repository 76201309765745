@mixin focus {
  &:focus {
    box-shadow: 0 0 0 3px $gray-300;
    border-radius: $roundness-m;
    outline: none;
  }
}

@mixin focus-dark {
  &:focus {
    box-shadow: 0 0 0 3px $gray-700;
    border-radius: $roundness-m;
    outline: none;
  }
}
