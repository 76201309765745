@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  display: flex;
  flex-wrap: wrap;
  padding: $space-s $space-m;
  
  width:100%;
  
}

.text {
  flex: 1;
  padding-left:$space-xs;
  padding-right: $space-s;

  @media screen and (max-width: $mobile) {
    //flex-basis: 75%; //666
  }
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
  
}

.subtitle {
  color: $gray-700;
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: ($iphonePro)-1) {
    margin-top: $space-xs;
  } 
  
  @media screen and (max-width: $mobile) {
    flex: 1;
   /* margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;*/
  }
}
