@import '../../styles/variables.scss';


.header {
  color: $gray-900;
  font-size: 1.071428571rem;
  line-height: 1.4;
  font-weight:400;
  //font-weight: bold;
  width:auto;
  
}
.multiwrap{
 
    display: flex;
    margin: 0;
    
  }
  
  .filterListAll
  {
    @media only screen and (min-device-width: $mobile) and (max-device-width: 1025px) and (orientation:landscape) {
      white-space: nowrap;
      width: 2ch;
      overflow: hidden;
      text-overflow: clip;
      }
    }

.inputRow {
  display: flex;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.wrap {
  align-items: center;
  display: flex;
  //justify-content: center;
  width: auto;

  & > * {
    min-width: 10%;
    margin-right: 5px;
  }

  @media screen and (max-width: $tablet) {
    padding: $space-m;
  }

  @media screen and (max-width: $mobile) {
    flex-wrap: wrap;
    padding: $space-m;

    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-s;

      &:nth-child(1n + 3) {
        margin-bottom: 0;
      }
    }
  }
}

.inputRow > * {
  flex: 1;
  margin: 0 $space-m $space-m 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $mobile) {
    margin-right: 0;

    &:last-child {
      margin-right: inherit;
    }
  }
}

  a.row {
    cursor: pointer;
  
    &:hover {
      background-color: $gray-100;
    }
  
    &:active {
      background-color: $gray-200;
    }
  }

  .row {
    align-items: center;
    border-bottom: 1px solid $gray-300;
    display: flex;
    height: 74px;
    
  
    &:first-child {
      border-radius: 3px 3px 0 0;
    }
  
    &:last-child {
      border-bottom: 0;
      border-radius: 0 0 3px 3px;
    }
  
    @media screen and (max-width: $mobile) {
      padding: 0 $space-xs;
      height: 85px;
    }
  }