@import '../../styles/variables.scss';

.clearAlarm {
  //max-width: 475px;
  padding-bottom: $space-xl;
}
.wrap {
  display: flex;
  
  align-items: center;
  & > * {
    flex: 1;
  }
  max-width: 170px;
}
.spacer {
  padding-left:$space-xxxs;
  max-width: 25px
}
.wyswigWrapper
{
  height: 20vh;
  line-height: 1.4;
  @media screen and (max-width: $mobile) {
    height: 15vh;
  }
}

.wyswigWrapper i, .border i
{
  font-style: italic!important;
}

.wyswigOuterWrapper [title="Styles"], .wyswigOuterWrapper [title="Link"], .wyswigOuterWrapper [title="Clear formatting"]  {
  display: none;
}