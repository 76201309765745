@import '../../styles/variables.scss';

.termsOfService {
  background-color: $white;
  margin: 0 auto;
  max-width: 800px;
  padding: $space-xs $space-m $space-xs $space-m;
  //text-align: justify;

  .h {
    font-weight: 500;
    margin-bottom: $space-xxxs;
    margin-top: $space-m;
    display: block;
  }

  p {
    margin-bottom: $space-s;
    line-height: 1;
  }
}