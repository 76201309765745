@import '../../styles/variables.scss';

.wrapper
{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper header {
    position: absolute;
}