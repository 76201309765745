@import '../../styles/variables.scss';

.wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: $space-l;

  @media screen and (max-width: $mobile) {
    padding: $space-m $space-s;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    padding: $space-xxxs;
  }
}

.column {
  max-width: 840px;
  width: 100%;
  @media screen and (max-width: $mobile) {
    width: 95%;
  }
  @media screen and (max-width: $smallMobile) {
    width: 100%;
  }
}
