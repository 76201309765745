@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $space-m;
  flex-wrap: wrap;

  & > * {
    min-width: 10%;
      margin-right: $space-xs;
  }

  @media screen and (max-width: $tablet) {
    padding: $space-m;

  }

  @media screen and (max-width: $mobile), only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2), only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2), only screen 
  and (min-device-width: 481px) and (max-device-width: 1024px) 
  and (orientation:landscape) 
  
  {
    justify-content: start;
    flex-wrap: wrap;
    padding: $space-xs;

    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-s;

      &:nth-child(1n + 3) {
        margin-bottom: $space-s;
      }
    }
  }

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) 
  
  {
    & > * {
      flex: unset;
      margin-bottom: $space-s;
      width: 33.3%;
      margin-right: 0;
    }
  }
}
