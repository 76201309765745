@import '../../styles/variables.scss';

.nav {
  align-items: center;
  background-color: $gray-100;
  display: flex;
  //flex-direction: column;
  height: 100%;
  //justify-content: flex-start;
  justify-content: center;
  overflow: auto;
  //padding: 0 0 $space-xs;
  width: 100%;

  @media all and (device-width: 1280px) and (device-height: 720px) {
    width: auto;
    padding: 0;
  }

  @media screen and (max-width: $mobile) {
    width: unset;
    flex-wrap: wrap;
    padding: 10px 0;
  }

}

.nav>a:focus {
  // @include focus-dark;

  box-shadow: 0 !important;
  // border-radius: $roundness-m;
  outline: none !important;
  border: 0 !important;
}

.logoLink {
  left: 20px;
  position: absolute;
  width: 28px;

  &>* {
    width: 28px;
  }

  @media screen and (max-width: $mobile) {
    display: none;
  }

  @media all and (device-width: 1280px) and (device-height: 720px) {
  }
}

.navItemLink {
  margin: 0 $space-s;

  @media all and (device-width: 1280px) and (device-height: 720px) {
    margin: 0 $space-xxxs;
  }
}

.accountLink {
  // margin-top: auto;
}

.break {
  width: 1px;
  height: 55px;
  background: $gray-800;
  // margin: $space-xs 0 $space-l;
}