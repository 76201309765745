@import '../../styles/variables.scss';

.support {
  background-color: $white;
  margin: 0 auto;
  width: 500px;
  padding: $space-xs $space-m $space-xs $space-m;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateX(-50%) translateY(-50%);

  .h {
    font-weight: 500;
    margin-bottom: $space-xxxs;
    margin-top: $space-m;
    display: block;
  }

  p {
    margin-bottom: $space-s;
    line-height: 1;
  }

  a:hover {
    text-decoration: underline;
  }
}