@import '../../styles/variables.scss';

.examplesWrap {
  overflow: hidden;
}

.examples {
  display: flex;
  flex-wrap: nowrap;
}

.example {
  background-color: $gray-300;
  height: 100%;
  width: 50%;

  &:first-child {
    margin-right: $space-s;
  }

  & > img {
    display: block;
    width: 100%;
  }
}
