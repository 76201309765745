@import '../../styles/variables.scss';

.status > * {
  border: none;
}

.details {
  border-top: 1px solid $gray-300;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $space-m;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: $tablet) {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-s;
    }
  }

  @media screen and (max-width: $mobile) {
    & > label:first-child {
      flex: 0 0 60%;
    }

    & > label:nth-child(2) {
      flex: 0 0 40%;
    }
  }
}

@media screen and (min-width: ($desktop-s)+1) and (max-width: $desktop-l)
{
  .details label
  {
    margin-right: $space-xxs;
  }

}

.version {
  line-height: 1;
}

.button {
  margin-left: auto;
  flex: none;

  @media screen and (max-width: $tablet) {
    margin-left: 0;
    margin-bottom: 0;
  }
}

.emptyWrap {
  padding: $space-m;
}

.emptyWrap {
  display: flex;
  padding: $space-l $space-m;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.icon {
  color: $gray-700;
  flex: 0 0 auto;
  margin-right: $space-m;
  margin-left: $space-xxs;
  position: relative;
  top: -6px;

  @media screen and (max-width: $mobile) {
    margin-bottom: $space-xxs;
    margin-right: 0;
    margin-left: 0;
    top: 0;
  }
}

.emptyContent {
  flex: 1;
}

.emptyInstructions {
  //margin-bottom: $space-l;

  & > h3 {
    margin: 2px 0 $space-s;
    line-height: 1.4;
  }

  & > p {
    margin-bottom: $space-s;
  }
}
.font{
  @media screen and (max-width: $mobile) {
    font-size:0.9rem !important;
  }
}
.wrapSmall{
  display: flex;
  align-items: center;
}
.type {
  line-height: 1;
  @media screen and (max-width: $mobile) {
    font-size:0.9rem;
  }
}
.typeStatus {
  line-height: 1;
  margin-left: $space-xxs;
  @media screen and (max-width: $mobile) {
    font-size:0.9rem;
  }
}
.iconErr {
  color: $red-600;
}
.wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.text {
  flex: 1;
  //padding: 0 $space-m;
  padding-left:$space-xs;
  padding-right: $space-s;
  @media screen and (max-width: $mobile) {
    flex-basis: 75%;
  }
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}
