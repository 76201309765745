@import '../../styles/variables.scss';

.frameHeader {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  padding: $space-s $space-m;
}

.offsetPadding {
  &:first-child {
    margin-top: (-$space-m);
  }

  margin: $space-m (-$space-m);
}

.icon {
  color: $gray-900;
  margin-right: $space-xs;
}
