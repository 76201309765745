@mixin carret($color: $gray-700) {
  width: 0;
  height: 0;
  margin-top: 4px;
  border: 4px solid transparent;
  border-top: 4px solid $color;
}

@mixin flag($color: $orange-300) {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: $color;
  border-top-color: $white;
}
