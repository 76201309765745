@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  border-radius: $roundness-m;
  border: 1px solid $gray-300;
  display: flex;
  flex-wrap: wrap;
  padding: $space-s $space-m;
  width:100%;
  @media screen and (max-width: $mobile) {
  
  padding:$space-xxs;
}
}
.wrapclear {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0 $space-s 0 0;

  @media all and (device-width: 1280px) and (device-height: 720px) {
   
  }

  @media screen and (min-width: $desktop-s) and (max-width: $desktop-l) and (orientation: landscape) {
    flex-basis: 100%;
  }


}
.text {
  flex: 1;
  padding: 0 $space-m;

  @media screen and (max-width: $mobile) {
    flex-basis: 75%;
  }
}
.spacer{
 margin-right: $space-s;

 @media screen and (max-width: $mobile) {
    margin-right:100%;
  display: inline-flex;
}

@media screen and (min-width: $desktop-s) and (max-width: $desktop-l) and (orientation: landscape)  {
  margin-right:100%;
display: inline-flex;
}

@media all and (device-width: 1280px) and (device-height: 720px) {
 
}

}
.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}

.subtitle {
  color: $gray-700;
  @media screen and (max-width: $mobile) {
    margin-top:$space-xxs;
    margin-bottom:$space-xxs;
  display: inline-flex;
  
}

@media screen and (min-width: $desktop-s) and (max-width: $desktop-l) and (orientation: landscape)  {
  margin-top:$space-xxs;
  margin-bottom:$space-xxs;
display: inline-flex;
}

@media all and (device-width: 1280px) and (device-height: 720px) {
  margin-top:$space-xxs;
  margin-bottom:$space-xxs;
display: inline-flex;
}
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
  @media all and (device-width: 1280px) and (device-height: 720px) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}
