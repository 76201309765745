@import '../../styles/variables.scss';

a.row {
  cursor: pointer;
 
  &:hover {
    background-color: $gray-100;
   margin-bottom:1px;
    border-top: 1px solid $gray-300;
    border-radius: 3px;
  }

  &:active {
    background-color: $gray-200;
    }
}
.row {
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  padding: $space-l $space-m;
  @media screen and (max-width: 400px) {
    padding: $space-xs $space-s;
    }
  height: 74px;
  //width: auto;
  min-width: 230px;
  width: 100%;
  float: left;
  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }
}

.multiwrap{
 
  display: flex;
  margin: 0;
  //width: 100%;
  max-width:250px;

}
.size--l {
  height: 90px;

  .subtitle {
    margin-top: $space-xxs;
  }

}

.title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-height: 1.2;
  @media screen and (max-width: $mobile) {
    line-height: 1.0;
    font-size: 1.0rem;
  }
  
}



.subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-height: 1.2;
  @media screen and (max-width: $mobile) {
    line-height: 1;
  font-size: 0.8rem;
  }


  
  
}

.subtitle {
  color: $gray-700;
  margin-top: $space-xxxs;
}

.left {
  padding-right: $space-xxs;
  @media screen and (min-width: 400px) {
  padding-left: $space-xxs;
  }
}

.rightMobile {
  
  color: $gray-700;
  align-items: center;
  height:75px;
  display: flex;
  padding-left:$space-m;
  margin-right:$space-l;
}


.outerDiv{
width: 100%;
align-items: center;
border-bottom: 1px solid $gray-300;
display: flex;
height: 74px;
justify-content: space-between;
max-width: calc(100vw - 36px);
// padding: 0 24px;
  

}
.leftDiv
{
flex-basis: 100%;
min-width: 0;
/*left:0;
width: 80%;
float: left;


@media all and (width: 320px) {
  width: 100%;
}
@media screen and (max-width: $mobile) {
  width: 60%;
}

@media screen and (max-width: $ipad) {
  width: 60%;
}
@media screen and (min-width: $desktop-s) {
  width: 85%;
}
*/

}
.rightDiv
{
right:0;
width: 20%;
float: right;
@media screen and (max-width: 320px) {
  width: 100%;
}
@media screen and (max-width: $mobile) {
  width: 40%;
}
@media screen and (max-width: $ipad) {
  width: 40%;
}
@media screen and (min-width: $desktop-s) {
  width: 15%;
}

@media screen and (width: $ipad) and (height: $ipadHeight) {
  width: 25%;
  
}
}		
.leftDivWider
{

left:0;
width: 70%;
float: left;


@media all and (width: 320px) {
  width: 100%;
}
@media screen and (max-width: $mobile) {
  width: 60%;
}

@media screen and (max-width: $ipad) {
  width: 60%;
}
@media screen and (min-width: $desktop-s) {
  width: 75%;
}


}
.rightDivWider
{
right:0;
width: 30%;
float: right;
@media screen and (max-width: 320px) {
  width: 100%;
}
@media screen and (max-width: $mobile) {
  width: 40%;
}
@media screen and (max-width: $ipad) {
  width: 40%;
}
@media screen and (min-width: $desktop-s) {
  width: 25%;
}

@media screen and (width: $ipad) and (height: $ipadHeight) {
  width: 25%;
  
}
}		

.titleContainer
{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}