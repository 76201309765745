@import '../../styles/variables.scss';

.checkboxWithLabel {
  display: block;
  user-select: none;
  max-width:200px;
}
.checkboxWithLabelFullwidth{
  display: block;
  user-select: none;
}

.checkboxWithLabelFullwidth.hideFromReport
{
  border: 1px #d9d9d9 solid;
  border-radius: 3px;
  display: inline-block;
    margin-right: unset;
    padding-right: 5px;
    padding-left: 2px;
}

.row {
  align-items: flex-start;
  display: flex;
}

.row.hideFromReport
{
  align-items: center;
  height: 25px;
  line-height: 25px;
}

.label {
  color: $gray-900;
  display: block;
  line-height: 1.56;
  margin-bottom: $space-xxs;
  min-height: 19px;
  padding-left: $space-xs;
}

.label.hideFromReport
{
  margin-bottom: unset;
  padding-left: 5px;
}

.description {
  color: $gray-700;
  display: block;
  line-height: 1.57;
  margin-left: 33px;
}