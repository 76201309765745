@import '../../styles/variables.scss';

// Styles go here
.wrap {
    width:100%;
    display: grid;
    padding: $space-xxxs;
    
    & > * {
      flex: 1;
      margin:$space-xxxxs;
    }
  }