@import '../../styles/variables.scss';

.wrap {
  display: flex;
}


.input {
  width: 50%;
  margin-right: $space-s;
}

@media all and (max-width: ($iphonePro)-1)
{
  .wrap
  {
    flex-wrap: wrap;
  }

  .input
  {
    flex-basis: 100%;
    margin: 0 0 $space-xxxs 0;
  }

  .wrap > button
  {
    margin-left: auto;
  }

}


