@import '../../styles/variables.scss';

.empty {
  font-weight: 500;
  padding: $space-l;
  text-align: center;
}
.wrap{
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
}
.wrap > div {
  margin:8px;
}