@import '../../styles/variables.scss';

.wrap {
  &::after {
    clear: left;
    content: '';
    display: table;
  }
}

.attachmentFrame {
  border-radius: $roundness-m;
  box-shadow: $depth-200;
  cursor: pointer;
  float: left;
  height: 120px;
  margin: 0 $space-s $space-s 0;
  overflow: hidden;
  width: 200px;

  @include focus;

  & img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
