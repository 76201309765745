/* autoprefixer grid: autoplace */
@import '../../styles/variables.scss';

.layout {
  display: grid;
  grid-template-columns: 720px 1fr;
  grid-template-rows: 1fr;

  @media screen and (max-width: $desktop-l) {
    grid-template-columns: 500px 1fr;
    grid-template-rows: 1fr;
  }

  @media screen and (max-width: $desktop-m) {
    grid-template-columns: 500px 1fr;
    grid-template-rows: 1fr;
  }

  @media screen and (max-width: $desktop-s) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.map {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  &::after {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.08),
      rgba(0, 0, 0, 0)
    );
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 12px;
    z-index: 3;
  }

  @media screen and (max-width: $desktop-s) {
    display: none;
  }
}

.layout--map-showing {
  @media screen and (max-width: $desktop-s) {
    display: block;

    & .content {
      display: none;
    }

    & .map {
      display: block;

      &::after {
        display: none;
      }
    }
  }
}

// asset map view
.layout--map-showing section:nth-child(2) > div:nth-child(2) // no empty space for "menu" button
{
  height: 100%;
  margin-top: 0;
}

.layout--map-showing section:nth-child(2) > div:last-child // hide "menu" button
{
  display: none;
}

.layout--map-showing div[class*='Map_backButtonContainer'] //section:nth-child(2) > div:nth-child(5) // display "back" button 
{
  @media screen and (max-width: $desktop-s)
  {
    display: block;
  }
}