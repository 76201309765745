@import '../../styles/variables.scss';

.wrap {
  display: none;
  margin-left: auto;

  @media screen and (max-width: $desktop-s) {
    display: block;
  }
}
