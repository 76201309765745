@import '../../styles/variables.scss';

.label {
  display: block;
  padding-bottom: $space-xs;
}

.icon {
  color: $gray-700;
  float: left;
  margin-right: $space-xxs;
  position: relative;
  top: -4px;
}

.light {
  color: $gray-700;
}
.right {
  color: $gray-700;
  margin-left: auto;
  //padding-left: $space-s;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.left {
 // padding-right: $space-s;
}
.row {
  align-items: center;
  display: flex;
 // margin-bottom: $space-xs;
  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 3px 3px;
  }

  @media screen and (max-width: $mobile) {
    padding: 0 $space-xs;
    height: 85px;
  }
}