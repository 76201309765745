@import '../../styles/variables.scss';

.details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: $tablet) {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-s;
    }
  }

  @media screen and (max-width: $mobile) {
    & > * {
      flex: 0 0 100%;
    }
  }
}

.removeAsset {
  text-align: right;
  margin-bottom: 0;

  @media screen and (max-width: $tablet) {
    text-align: left;
  }
}

.emptyWrap {
  display: flex;
  padding: $space-xs 0;

  @media screen and (max-width: $mobile) {
    display: block;
    padding: 0;
  }
}

.icon {
  color: $gray-700;
  flex: 0 0 auto;
  margin-right: $space-m;
  margin-left: $space-xxs;
  position: relative;
  top: -6px;

  @media screen and (max-width: $mobile) {
    margin-bottom: $space-xxs;
    margin-right: 0;
    margin-left: 0;
    top: 0;
  }
}

.emptyContent {
  flex: 1;
}

.emptyInstructions {
  margin-bottom: $space-l;

  & > h3 {
    margin: 2px 0 $space-s;
    line-height: 1.4;
  }

  & > p {
    margin-bottom: $space-s;
  }
}
