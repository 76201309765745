@import '../../styles/variables.scss';

.frameWrap {
  margin-bottom: $space-xl;
  max-width: 380px;
  text-align: center;
  width: 100%;

  @media screen and (max-height: 530px) {
    align-self: flex-start;
    margin: $space-xl 0;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    margin: $space-m;
  }
}

.inputWrapLogin {
  text-align: left;
}

.logoWrap {
  padding: $space-m $space-m $space-xs $space-xl;
}
