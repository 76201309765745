@import '../../styles/variables.scss';

.wrap {
  padding: $space-m;

  @media screen and (max-width: $mobile) {
    padding: $space-s;
  }
}
.noBottomPadding {
  padding-top: $space-m;
  padding-left: $space-m;
  padding-right: $space-m;

  @media screen and (max-width: $mobile) {
    padding-top: $space-s;
    padding-left: $space-s;
    padding-right: $space-s;
  
  }
}