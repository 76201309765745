@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  padding-top: 120px;//nav height
  @media screen and (max-width: $mobile) {
  padding-top: 140px;
  }
}

.overlay {
  background-color: rgba($black, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

}

.frame {
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
  max-height: 84vh;
  max-width: 720px;
  min-height: 84vh;
  overflow-y: hidden;
  padding-bottom: 84px;
  position: relative;
  width: 90%;
}
.framesmall {
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-300;
  max-height: 35vh;
  max-width: 720px;
  min-height: 27vh;
  overflow-y: hidden;
  
  position: relative;
  width: 100%;
  @media screen and (max-width: $mobile) {
    max-height: 500px;
    //height: 360px;
   
  }

  @media screen and (max-width: $ipadHeight) {
    max-height: 500px;
    height: 360px;
  }

  @media screen and (min-width: $ipadHeight+1) and (max-width: $desktop-l) {
    min-height: 40vh;
  }

  @media all and (width: 1280px) and (height: 720px) {
    max-height: 500px;
    height: 360px;
  }

  @media all and (min-width:  $desktop-l+1) and (max-width: ($desktop-xl)-1) {
   
    min-height: 35vh;
  }

}

.scrollWrap {
  -webkit-overflow-scrolling: touch;
  bottom: 84px;
  left: 0;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
}

.innerFrame {
  padding: $space-m $space-m 0 $space-m;
}

.footer {
  align-items: center;
  background-color: $white;
  border-radius: 0 0 $roundness-m $roundness-m;
  border-top: 1px solid $gray-300;
  bottom: 0;
  box-shadow: $depth-300;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: $space-m;
  position: absolute;
  width: 100%;
  z-index: 9;
  @media screen and (max-width: $mobile) {
    padding: $space-xs;
  }
  & > * {
    margin-left: $space-xxs;
  }
}

.header {
  align-items: center;
  background-color: $white;
  border-radius: 0 0 $roundness-m $roundness-m;
  border-top: 1px solid $gray-300;
  top: 0;
  box-shadow: $depth-300;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: $space-m;
  position: absolute;
  width: 100%;
  z-index: 9;
  @media screen and (max-width: $mobile) {
    padding: $space-xs;
  }
  & > * {
    width: 100%;
    }
}

.touch {
  .overlay {
    background-color: $white;
  }

  .frame {
    border-radius: 0;
    height: 100%;
    max-height: none;
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
.footer button
{
  padding: 0 16px;
}
}