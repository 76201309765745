@import '../../styles/variables.scss';

// Styles go here
.maxSize{
    max-width: 100%;
    max-height: 200px !important;
}
.smallSize{
    max-width: 100%;
    max-height: 120px !important;
}
.max, .max > div, .max > div > img {
    @media screen and (min-width: 450px) {
    //max-height:200px;
    height: 200px;
    }
}

.small{
    max-height:120px;
}