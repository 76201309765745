@import '../../styles/variables.scss';

.wrap {
  color: $gray-700;
  height: 42px;
  text-align: center;
  width: 48px;
}

.value {
  padding-top: 2px;
}
