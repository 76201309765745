@import '../../styles/variables.scss';

.frame {
  background-color: $white;
  border-radius: $roundness-m;
  box-shadow: $depth-400;
  padding: $space-s;
  position: relative;
  width:100%;

}