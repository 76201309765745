@import '../../styles/variables.scss';

.totalImpactsNumber
{
  color:$gray-700;
  margin-left: auto;
  margin-right: auto;
}
.week{
padding-left: $space-l;
margin-right: $space-l;
}
.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}
.center{
  position: absolute;
  left: 50%;
  margin-left: -130px;
  top:10px;

  &>*{
    margin-left: 10px;
  }
}
.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}

.detailsHead {
  
  .value {
    font-weight: 500;
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: $space-m;
  border-bottom: 1px solid $gray-300;

  & > * {
    flex: 1;
    flex-basis: 100%;
    margin-bottom: 30px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $mobile) {
    & > * {
      flex: 0 0 100%;
    }
  }
}
.notificationsControlWrapper
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
 // border-top: 1px $gray-300 solid;
}

.notificationsControlWrapper span
{
  margin-bottom: 0;
}


.details{

  .value {
    color: $black;
  }
  
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: $space-m;

  & > * {
    flex: 1;
    flex-basis: 33.3%;
    margin-bottom: 0;
  }

  @media screen and (min-width: $desktop-l) {

  & > *:last-child {
    flex-basis: 10%;
  }
  
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {

    & > *:last-child {
      flex-basis: 10%;
    }
    
    }
  @media screen and (device-width: 1024px) {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-l;
    }

    & > *:nth-last-child(-n+2)
    {
      //margin-bottom: 0!important;
    }
  }

  @media screen and (max-width: 700px), screen and (min-width: $desktop-s) and (max-width: $desktop-l)
  {
    & > * {
      flex: 0 0 50%;
      margin-bottom: $space-l;
  }
}



  @media print and (device-width: $tablet) {
    & > * {
      flex: 0 0 33.3%;
      margin-bottom: $space-l;
    }

    > *:nth-last-child(-n+2)
    {
      margin-bottom: 0!important;
    }
  }

  @media screen and (max-width: $mobile) {
    & > * {
      flex: 0 0 100%;
      margin-bottom: $space-m;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.bannerImage {
  background-color: $gray-900;
  border-radius: $roundness-m $roundness-m 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  & img {
   // max-height: 100%;
   // max-width: 100%;
  }
}



.sliderPadding{
  padding:$space-xs;
}
.mainInfo {
  padding: $space-m;
}

.delete {
  text-align: center;
}
.floatright{
  position: relative;
 
  top: 5px;
}
.floatleft {
  position: absolute;
  left: 5px;
  

> * {
    vertical-align: middle;
    margin-bottom: $space-xxxs;
  }
}

.floatleft div {
  vertical-align: middle;
  margin-bottom: $space-s;
}

.wrapper{
  display:inline-flex;
  width:100%;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.w30{
  margin-left: 24px;
  margin-right: 20px;
  width:10%;
  align-items: center;

}

.w70{
  align-items: center;
  
  width:90%;
  height:35px;
}
.smallwrap{
  margin-right: 0;
  margin-left: 20px;
  width: 20px;
}
.break {
 
  height: 1px;
  width: 100%;
  background: $gray-500;
  margin: $space-l 0 $space-l, 0;
}

.uploadButton {
  cursor: pointer;

  position: absolute;
  right: 5px;
  top: 5px;

  & > button:focus {
    box-shadow: none;
  }

  &:active > button {
    box-shadow: none !important;
    color: $gray-700;
  }
}

.details {
  display: flex;
  flex-direction: column;
}


.input {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer !important;
  display: block;
  font-size: 100px;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.text {
  flex: 1;
  padding: 0 $space-m;

  @media screen and (max-width: $mobile) {
    flex-basis: 75%;
  }
}

.status {
  padding-top: $space-xxxs;
  margin-bottom: $space-xxxs;
}

.subtitle {
  color: $gray-700;
}

.meta {
  align-items: center;
  display: flex;
  margin-left: auto;

  @media screen and (max-width: $mobile) {
    flex: 1;
    margin-top: $space-m;
    padding-left: $space-l;
    margin-left: 0;
    justify-content: space-between;
  }
}

.editButton
{
  position: absolute;
  right: 24px;
  top: 24px;

}

  .admin
{
  max-width: calc(100% - 90px);
}

.simpleModeContainer
{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.simpleModeContainer > .value
{
  font-weight: bold;
  font-size: 150%;
  text-align: center;
  max-width: unset;
}

.simpleModeContainer > button > span
{
  font-size: 165%;
  place-content: center;
}

.simpleModeContainer > button i 
{
  font-size: 150%!important;
}

.simpleModeContainer > *
{
  margin-bottom: $space-m;
}

.simpleModeContainer > button
{
  min-height: 15vh;
}






//tables
table {
  padding: $space-m !important;
  width: 100%;
}

tr:hover {
  background-color: $gray-100;
}

tr:first-child:hover {
  background-color: initial;
}

td,
th {
  padding: 10px 0 10px 0 !important;
  text-align: center;
}

td a {
  display: block;
}

tr:last-child {
  border-bottom: 0 !important;
}

tr {
  border-bottom: $gray-300 1px solid !important;
}

tr.emptyRow {
  border: 0 !important;
}

tr.emptyRow:hover {
  background-color: initial;
}

td:last-child {
//  text-align: right;
//  padding-left: 24px !important;
  vertical-align: middle;
}

th > div {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}



div[class*='MuiAccordion-root'] {
  margin: 16px 0!important;
}


