@import '../../styles/variables.scss';

.filterBar { 
  align-items: center;
  border-bottom: 1px solid $gray-300;
  display: flex;
  justify-content: space-between;
  padding: $space-xs $space-m;
  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) 
  {
  padding: $space-xs 6px;
  }
}

.status {
  align-items: center;
  display: flex;
}

.totalText {
  color: $gray-700;
}

.loading {
  margin-right: $space-xs;
}
.text{
  display: flex;
  padding-left:$space-xxs;
}
.events {
 padding: $space-s;
 @media only screen and (min-device-width: 320px) and (max-device-width: 374px) 
{
padding: 0;
}

}
.wrap {
  width:100%;
  display: flex;
  padding: $space-xxs;
  
  & > * {
    flex: 1;
  }
}

@media screen and (max-width: 670px), screen and (min-width: ($desktop-s)+1) and (max-width: $desktop-l)
{
  .spacer
  {
    height: 2px;
  }
}

  .spacer
  {
    width:5px;
  }

